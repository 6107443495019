<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <b-form>
      <b-form-row>
        <form-thux-horizontal-autocomplete
          :validator="$v.form.performer"
          :label-form="'Performer' | translate"
          class-form="col-lg-6 my-2"
          :disabled="!formEditable"
          :label-search="'name'"
          :value="form.performer ? { id: form.performer, name: form.performer_name } : null"
          :options="performerOptions"
          :placeholder="$t('Search by last name')"
          :placeholder-field="form.performer_name"
          @search="onSearchPerformer"
          @select="selectPerformer"
          @clear="clearPerformer"
        />
        <form-thux-horizontal-datetime
          :validator="$v.form.date_inspection"
          label-form="Date inspection"
          class-form="col-lg-6 my-2"
          type="date"
          :disabled="!formEditable"
          :value="dateInspection"
          :placeholder="$t('Enter the date inspection')"
          @change="dateChange($event, 'date_inspection')"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-text-area
          :validator="$v.form.reason"
          label-form="Reason"
          class-form="col-lg-12 my-2"
          labelColsSm="1"
          labelColsLg="1"
          :disabled="!formEditable"
          :value="form.reason"
          :placeholder="$t('Enter the reason')"
          @change="$set(form, 'reason', $event)"
        />
      </b-form-row>
    </b-form>
    <thux-vue-file-agent v-if="!id" @update-attachments="updateAttachments"/>
    <div class="mt-3 f-w-600 d-flex justify-content-end">
      <b-button
        class="mr-3"
        variant="default"
        @click.prevent="formEditable ? cancel() : closeForm()">
        {{ formEditable ? 'Cancel' : 'Close' | translate }}
      </b-button>
      <b-button
        variant="primary"
        v-has-perms="editRolePerm"
        @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
        :disabled="$v.form.$invalid">
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_INSPECTION_TYPES } from './store'
import { TYPES as ORGANIZATION_INSPECTION_ATTACHMENTS_TYPES } from '../organization-inspection-attachment/store'
import moment from 'moment'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import ThuxVueFileAgent from '../../../../components/ThuxVueFileAgent'
// controllare import PerformerMixin
import PerformerMixin from '../../../yard/yard/mixins/PerformerMixin'

import { TYPES as ORGANIZATION_TYPES } from '../store'
import { cloneDeep } from 'lodash'
import FormThuxHorizontalTextArea from '../../../../components/form-thux-horizontal-components/FormThuxHorizontalTextArea.vue'

export default {
  name: 'OrganizationInspectionEdit',
  props: {
    organization: { required: true }
  },
  mixins: [
    ThuxDetailPanelMixin,
    PerformerMixin
  ],
  components: {
    ThuxVueFileAgent,
    FormThuxHorizontalTextArea
  },
  data () {
    return {
      pageName: 'Inspection',
      rolePerm: 'organization_organizationinspection_retrieve',
      editRolePerm: 'organization_organizationinspection_update',
      dateInspection: undefined,
      formAttachments: []
    }
  },
  created () {
    this.$set(this, 'formEditable', false)
    if (!this.id) {
      this.$set(this, 'formEditable', true)
    }
    this.setDetail({})
  },
  computed: {
    ...mapGetters({
      ...ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.DETAIL.GETTERS
    }),
    title: function () {
      if (this.id) {
        const number = this.detail.number || ''
        if (this.formEditable) {
          return `${this.$t('Edit')} ${this.$t(this.pageName).toLowerCase()} n°${number}`
        } else {
          return `${this.$t('Show')} ${this.$t(this.pageName).toLowerCase()} n°${number}`
        }
      } else {
        return `${this.$t('Add')} ${this.$t(this.pageName).toLowerCase()}`
      }
    }
  },
  methods: {
    ...mapMutations({
      setDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.MUTATIONS.setDetail
    }),
    ...mapActions({
      ...ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.DETAIL.ACTIONS,
      getOrganizationInspectionList: ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.LIST.ACTIONS.getList,

      // attachments
      createAttachment: ORGANIZATION_INSPECTION_ATTACHMENTS_TYPES.GENERIC.organization.organizationinspectionattachment.DETAIL.ACTIONS.create
    }),
    init () {
      this.cleanErrors()
      if (this.id) {
        this.retrieve({ id: this.id }).then(
          () => {
            this.makeFormReadonly()
            this.setForm()
          }
        )
      } else {
        this.makeFormEditable()
      }
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      if (form.date_inspection) {
        this.$set(this, 'dateInspection', moment(new Date(form.date_inspection)))
      } else {
        this.$set(this, 'dateInspection', null)
      }
      this.$set(this, 'form', form)
      this.$set(this, 'formInitial', cloneDeep(form))
      this.$set(this, 'isFormChanged', false)
    },
    updateAttachments (attachments) {
      this.$set(this, 'formAttachments', attachments)
      this.$set(this, 'isFormAttachmentsChanged', false)
    },
    createOrUpdateArrayForm (list, createName) {
      const promises = []
      list.forEach((form) => {
        if (!form.id) {
          form.inspection = this.detail.id
          promises.push(this[createName](form))
        }
      })
      return promises
    },
    onSubmit () {
      const formData = Object.assign({}, this.form)
      formData.organization = this.organization
      const formAttachmentsData = Object.assign([], this.formAttachments)
      const promises = []
      if (!this.id) {
        this.create(formData).then(() => {
          if (formAttachmentsData.length > 0) {
            promises.push(...this.createOrUpdateArrayForm(
              formAttachmentsData,
              'createAttachment'
            ))
          }
          Promise.all(promises).then(() => {
            this.closeForm()
            this.getOrganizationInspectionList()
          })
        })
      } else {
        this.update(formData).then(() => {
          this.makeFormReadonly()
        })
      }
    }
  },
  validations: {
    form: {
      performer: { required },
      date_inspection: {},
      reason: {},
      number: {}
    },
    formAttachments: {
      $each: {
        name: { required },
        attachment: { required }
      }
    }
  }
}
</script>
