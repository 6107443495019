<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showEditButton="showEditButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-edit-form="$emit('open-edit-form', $event)"
    >
      <template slot="other-buttons-actions-top" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="showAttachmentsRolePerm"
          :title="'Show attachments' | translate"
          @click="$emit('show-attachments', { id: row.item.id, num: row.item.number })"
        >
          <i class="fas fa-paperclip mr-1"></i>
          <span class="font-weight-bold" style="font-size: .8rem">
            {{ row.item.attachment_count ? row.item.attachment_count : 0 }}
          </span>
        </b-btn>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="downloadPDFRolePerm"
          :title="'Download PDF' | translate"
          @click.prevent="downloadPDF(row.item)"
        >
          <i class="fas fa-file-download"></i>
        </b-btn>
              <!-- <b-btn
                variant="outline-primary"
                :title="'Remove inspection' | translate"
                @click="showConfirmModal(row.item)"
                <i class="fas fa-times"></i>
              </b-btn> -->
      </template>
    </thux-table>
    <ConfirmDialog
      v-if="showDeleteModal && inspectionToDelete.id"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this inspection?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="deleteInspection"
    >
        <template slot="body">
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="[inspectionToDelete]"
            :fields="inspectionFields"
          >
            <template slot="cell(date_inspection)" slot-scope="row">
              {{ row.item.date_inspection | date }}
            </template>
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_INSPECTION_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import ConfirmDialog from '../../../../components/ConfirmDialog'

export default {
  name: 'OrganizationInspectionsTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: { ConfirmDialog },
  data () {
    return {
      inspectionFields: [
        {
          key: 'number',
          label: this.$t('Number'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'performer_name',
          label: this.$t('Performer'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          key: 'date_inspection',
          label: this.$t('Date inspection'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        }
      ],
      showDeleteModal: false,
      inspectionToDelete: {},
      fields: [
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'number',
          label: this.$t('Number'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'number'
        },
        {
          key: 'performer__name',
          label: this.$t('Performer'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'performer_name'
        },
        {
          key: 'date_inspection',
          label: this.$t('Date inspection'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          type: 'date',
          value: 'date_inspection'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      // Permissions
      showAttachmentsRolePerm: 'organization_organizationinspectionattachment_list',
      downloadPDFRolePerm: 'organization_organizationinspection_print_pdf'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.LIST.GETTERS.selectAll,
      selectedList: ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return hasPerm('organization_organizationinspection_update')
    }
  },
  methods: {
    ...mapMutations({
      setList: ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.LIST.MUTATIONS.setList,
      setSelectedList: ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.LIST.ACTIONS,
      delete: ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.DETAIL.ACTIONS.delete,
      download: ORGANIZATION_INSPECTION_TYPES.GENERIC.organization.organizationinspection.DETAIL.ACTIONS.download
    }),
    downloadPDF (item) {
      const fileName = `${this.$t('Inspection')}_${item.number}_del_${item.date_inspection}_${item.performer_name}.pdf`
      this.download({ id: item.id, action: 'print-pdf', simpleFileName: fileName })
    },
    showConfirmModal (instance) {
      this.$set(this, 'inspectionToDelete', instance)
      this.$set(this, 'showDeleteModal', true)
    },
    closeConfirmModal () {
      this.$set(this, 'inspectionToDelete', {})
      this.$set(this, 'showDeleteModal', false)
    },
    deleteInspection () {
      const promise = this.delete(this.inspectionToDelete)
      promise.then(
        () => {
          setTimeout(() => {
            this.$emit('refresh-list')
            this.closeConfirmModal()
          }, 500)
        }
      )
    }
  }
}
</script>
